import React from 'react'
import { Card } from "flowbite-react";

const privacy = () => {
    return (
        <div>
            <div class="grid grid-rows-2 gap-4 place-items-center">
                <div class="col-span-2 mt-3 -mb-40">
                    <Card className="max-w-2xl">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Privacy Policy
                        </h5>
                        <p><strong>Last Updated: June 14, 2024</strong></p>
                        <p>Welcome to TradeRun! Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our game "TradeRun" and its associated services. By accessing or using TradeRun, you agree to this Privacy Policy.</p>

                        <h2>1. Information We Collect</h2>

                        <h3>1.1 Personal Data</h3>
                        <ul>
                            <li><strong>Account Information:</strong> When you sign up, we collect your username, email address, and password.</li>
                            <li><strong>Profile Information:</strong> This may include your profile picture and other information you choose to provide.</li>
                        </ul>

                        <h3>1.2 Usage Data</h3>
                        <ul>
                            <li><strong>Gameplay Data:</strong> Information about your gameplay, including achievements, in-game purchases, and progress.</li>
                            <li><strong>Device Information:</strong> Information about the device you use to access the game, such as your IP address, device type, operating system, and browser type.</li>
                        </ul>

                        <h3>1.3 Cookies and Tracking Technologies</h3>
                        <p>We use cookies and similar tracking technologies to track the activity on our game and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

                        <h2>2. How We Use Your Information</h2>
                        <p>We use the collected data for various purposes:</p>
                        <ul>
                            <li>To provide and maintain the game</li>
                            <li>To notify you about changes to our game</li>
                            <li>To allow you to participate in interactive features</li>
                        </ul>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                            Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
                        </p>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default privacy