import React from 'react'
import SlideShow from './slideshow';
import { Carousel, Card, Button, Timeline } from "flowbite-react";
import { HiArrowNarrowRight, HiCalendar } from "react-icons/hi";

function main() {
  return (
    <div>
      <div className="white-background">
        <div class="grid grid-col-3 gap-10 place-items-center">
          <div className="col-span-3">
            <Timeline horizontal>
              <Timeline.Item>
                <Timeline.Content>
                  <img class="h-auto max-w-full rounded-lg w-96 h-96" src="./screenshot1.png" alt="image description"/>
                  <Timeline.Title>Classic Main Menu</Timeline.Title>
                  <Timeline.Body>
                    The nostalgic game design that many people <br/> remembers being remade on unity engine
                  </Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
              <Timeline.Item>
                <Timeline.Content>
                  <img class="h-auto max-w-full rounded-lg w-96 h-96" src="./screenshot2.png" alt="image description"/>
                  <Timeline.Title>Classic Shop</Timeline.Title>
                  <Timeline.Body>
                    Get free items from shop by collecting <br/> coins also you can get coins from trading
                  </Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
              <Timeline.Item>
                <Timeline.Content>
                  <img class="h-auto max-w-full rounded-lg w-96 h-96" src="./screenshot4.png" alt="image description"/>
                  <Timeline.Title>Classic Achievements</Timeline.Title>
                  <Timeline.Body>
                   By Doing Achievements you can get free coins <br/>and XPs and much more! just keep playing
                  </Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
            </Timeline>
          </div>
          <div className="col-span-3">
            <Button href="../download" color="dark">
              Download the game
              <svg className="-mr-1 ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </div>
          <div className="col-span-3 mb-12"></div>
        </div>
      </div>
    </div>
  )
}

export default main