import React, {useEffect} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Main from './components/main';
import Download from './components/download';
import Signup from './components/signup';
import Ranking from './components/ranking';
import Privacy from './components/privacy';
import DeleteAccount from './components/deleteaccount';
import { initFlowbite } from 'flowbite'

function App() {
  useEffect(() => {
    initFlowbite();
  }, []);
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <div className='grey-background'>
            <Routes>
              <Route path="/" exact element={<Main/>} />
              <Route path="/ranking" element={<Ranking/>} />
              <Route path="/download" element={<Download/>} />
              <Route path="/signup" element={<Signup/>} />
              <Route path="/privacy" element={<Privacy/>} />
              <Route path="/account/delete" element={<DeleteAccount/>} />
            </Routes>
        </div>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
