import React, { useState } from 'react'
import { Card, Button, Label, TextInput } from "flowbite-react";

const DeleteAccount = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleDeleteAccount = () => {
        // Replace this with actual account deletion logic
        alert(`Account deletion process initiated for username: ${username}`);
    };

    return (
        <div>
            <div className="grid grid-rows-2 gap-4 place-items-center">
                <div className="col-span-2 mt-3 -mb-40">
                    <Card className="max-w-2xl">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Delete Account
                        </h5>
                        <p>We are sorry to see you go. If you would like to delete your TradeRun account, please follow the instructions below. Deleting your account will remove all your data permanently.</p>

                        <h2>How to Delete Your Account</h2>
                        <p>To delete your account, please provide your username and password below and click the "Delete Account" button. Once you delete your account, all your data including your profile information, gameplay data, and any achievements or progress will be permanently deleted and cannot be recovered.</p>

                        <div className="mt-4">
                            <Label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Username
                            </Label>
                            <TextInput
                                id="username"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your username"
                                required
                                className="mb-4"
                            />

                            <Label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Password
                            </Label>
                            <TextInput
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                required
                                className="mb-4"
                            />
                        </div>

                        <Button color="failure" onClick={handleDeleteAccount}>
                            Delete Account
                        </Button>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount
